import { projectApi } from 'api'
import { PaginatedResponse, PaginationParams } from 'api/common/types'
import { Project, ProjectFilter } from 'types/projects/projects'

export const fetchProjectsListApi = ({
  search,
  ownership,
  type,
  hierarchy,
  status,
  tenant,
  includeEmptyHierarchyLevel = false,
  attributes,
  page = 1,
  itemsPerPage = 30,
}: PaginationParams<Omit<ProjectFilter, 'client' | 'market' | 'brand' | 'viewMode'>>) => {
  const filteredHierarchy = hierarchy?.filter(Boolean) || []

  return projectApi.post<PaginatedResponse<Project>>(
    '/projects/search',
    {
      ...(!!search?.length && { inputText: search }),
      ...(!!ownership?.length && { 'filter[ownership]': ownership }),
      ...(!!type?.length && { 'filter[type]': type }),
      ...(!!filteredHierarchy?.length && { 'filter[hierarchy]': filteredHierarchy }),
      ...(!!status?.length && { 'filter[status]': status }),
      ...(!!tenant?.length && { 'filter[tenant]': tenant }),
      'filter[includeEmptyHierarchyLevel]': includeEmptyHierarchyLevel,
      'filter[customAttributes]': attributes,
    },
    {
      params: {
        page,
        itemsPerPage,
      },
    },
  )
}
