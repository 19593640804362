import { TabsChangeEventDetail } from '@platform-ui-kit/components-library'
import { WppDivider, WppTab, WppTabs } from '@platform-ui-kit/components-library-react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  ActivityTabsTypes,
  activityTabs,
} from 'pages/project/components/canvas/linearCanvas/components/item/linearActivity/detailsModal/ActivityDetailsModal'
import { ActivityDetailsModalApps } from 'pages/project/components/canvas/linearCanvas/components/item/linearActivity/detailsModal/ActivityDetailsModalApps'
import { ActivityDetailsModalResources } from 'pages/project/components/canvas/linearCanvas/components/item/linearActivity/detailsModal/ActivityDetailsModalResources'
import { ActivityItem } from 'types/projects/workflow'

interface Props {
  tabs: typeof activityTabs
  activity: ActivityItem
  setSelectedAppId: (appId: string | null) => void
}

type ActivityTabId = (typeof activityTabs)[number]['id']

export const ActivityDetailsModalTabs = ({ tabs, activity, setSelectedAppId }: Props) => {
  const { t } = useTranslation()

  const [currentTab, setCurrentTab] = useState<ActivityTabId>(ActivityTabsTypes.apps)

  useEffect(() => {
    setCurrentTab(tabs[0].id)
  }, [tabs])

  const handleTabChange = useCallback((event: CustomEvent<TabsChangeEventDetail>) => {
    setCurrentTab(event.detail.value as ActivityTabId)
  }, [])

  const sortedApps = useMemo(() => {
    return activity.items.filter(item => !item.application.hiddenFromAll).sort((a, b) => a.orderNumber - b.orderNumber)
  }, [activity.items])

  return (
    <>
      <WppTabs value={currentTab} size="s" onWppChange={handleTabChange} hidden={tabs.length === 1}>
        {tabs.map(({ id, value }) => (
          <WppTab key={id} value={id}>
            {t(value)}
          </WppTab>
        ))}
      </WppTabs>
      {tabs.length === 1 && <WppDivider />}

      {
        {
          apps: <ActivityDetailsModalApps apps={sortedApps} setSelectedAppId={setSelectedAppId} />,
          resources: (
            <ActivityDetailsModalResources
              files={activity.files}
              externalLinks={activity.externalLinks}
              activityId={activity.id}
            />
          ),
        }[currentTab]
      }
    </>
  )
}
