import { projectApi } from 'api'
import { ProjectFilter } from 'types/projects/projects'
import { ProjectsTimeline } from 'types/projects/projectsTimeline'

export const fetchProjectsTimelineApi = ({
  search,
  ownership,
  type,
  hierarchy,
  status,
  attributes,
  tenant,
  includeEmptyHierarchyLevel = false,
}: Omit<ProjectFilter, 'client' | 'market' | 'brand' | 'viewMode'>) => {
  const filteredHierarchy = hierarchy?.filter(Boolean) || []

  return projectApi.post<ProjectsTimeline>('/projects/timeline', {
    inputText: search,
    ...(!!ownership?.length && { 'filter[ownership]': ownership }),
    ...(!!type?.length && { 'filter[type]': type }),
    ...(!!filteredHierarchy?.length && { 'filter[hierarchy]': filteredHierarchy }),
    ...(!!status?.length && { 'filter[status]': status }),
    ...(!!tenant?.length && { 'filter[tenant]': tenant }),
    'filter[customAttributes]': attributes,
    'filter[includeEmptyHierarchyLevel]': includeEmptyHierarchyLevel,
  })
}
