import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useFetchWorkflowTemplatesCountApi } from 'api/templates/queries/useFetchWorkflowTemplatesCountApi'
import { useToast } from 'hooks/useToast'
import { ProjectAttribute, ProjectAttributeType } from 'types/projects/attributes'
import { Project } from 'types/projects/projects'
import { projectDateFormat } from 'utils/common'

export type ProjectDate = Pick<Project, 'startDate' | 'endDate'>

export const normalizeProjectFormDate = ([startDate, endDate]: Date[]): ProjectDate => ({
  startDate: startDate && startDate.getTime() !== endDate?.getTime() ? format(startDate, projectDateFormat) : null,
  endDate: endDate ? format(endDate, projectDateFormat) : null,
})

export const getInitialProjectFormDate = ({ startDate, endDate }: ProjectDate): Date[] => {
  const date = []

  //FYI: DatePicker range component requires 2 dates, so if no start date - we need to provide for UI endDate - endDate range which is interpreted as endDate.
  startDate && date.push(new Date(startDate))
  !startDate && endDate && date.push(new Date(endDate))
  endDate && date.push(new Date(endDate))

  return date
}

export const useCreateProjectTemplatesCount = () => {
  const { showToast } = useToast()
  const { t } = useTranslation()
  const {
    data: templatesCount,
    isLoading: isTemplatesCountLoading,
    isError: counterError,
  } = useFetchWorkflowTemplatesCountApi()

  const [isErrorToastDisplayed, setIsErrorToastDisplayed] = useState(false)

  useEffect(() => {
    if (counterError && !isErrorToastDisplayed) {
      showToast({
        type: 'error',
        message: t('project.canvas.toast.failed_workflow_count'),
      })
      setIsErrorToastDisplayed(true)
    } else if (!counterError && isErrorToastDisplayed) {
      // Clear the error toast if there's no error and the toast is displayed
      setIsErrorToastDisplayed(false)
    }
  }, [counterError, isErrorToastDisplayed, showToast, t])

  return { templatesCount, isTemplatesCountLoading, counterError }
}

export const fixDefaultValueForForm = (attribute: ProjectAttribute) => {
  let defaultValue = attribute.defaultValue
  // update defaults values for the form compatability
  switch (attribute.type) {
    case ProjectAttributeType.TEXT_INPUT:
    case ProjectAttributeType.TEXT_AREA:
      defaultValue = attribute.defaultValue || ''
      break

    case ProjectAttributeType.MULTI_SELECT:
    case ProjectAttributeType.DATE_RANGE:
      defaultValue = attribute.defaultValue || []
      break
  }

  return { ...attribute, defaultValue }
}

export const getAttributesDefaults = (attributes: ProjectAttribute[]): Record<string, string> => {
  return attributes.reduce((acc, { contractName, defaultValue }) => ({ ...acc, [contractName]: defaultValue }), {})
}
