import { useTranslation } from 'react-i18next'

import { useHierarchy } from 'hooks/useHierarchy'
import { HierarchySelect } from 'pages/components/projectModal/components/hierarchySelect/HierarchySelect'
import { WITHOUT_HIERARCHY } from 'utils/common'

export const HierarchyFilterControl = () => {
  const { t } = useTranslation()
  const { hierarchyOrder } = useHierarchy()

  return hierarchyOrder?.map((hierarchy, index) => {
    const emptyOption = {
      value: WITHOUT_HIERARCHY,
      label: t('modals.dashboard_filters.without_hierarchy', { hierarchy: hierarchy.toLowerCase() }),
    }
    return (
      <HierarchySelect key={hierarchy} index={index} hierarchy={hierarchy} controlPrefix="" emptyOption={emptyOption} />
    )
  })
}
