import { MayBeNull, OsContext, OsContextApi, SelectedHierarchy } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import { useEffect, useMemo } from 'react'

import { NavigationDefaults } from 'api/tenant/config'
import { useFetchNavigationNodeParents } from 'api/tenant/queries/useFetchNavigationNodeParents'
import { useProject } from 'hooks/useProject'
import { resolveHierarchyPath, resolveSelectedHierarchy } from 'utils/coreContextValues'

interface ProjectChildContext {
  osApi: OsContextApi
  osContext: MayBeNull<OsContext>
  isError: boolean
}

// osContext does not contain all correct data for child apps
export const useProjectChildContext = ({ parentUrl }: { parentUrl?: string }): ProjectChildContext => {
  const { osApi, osContext: orchestrationOsContext } = useOs()
  const { project } = useProject()

  // let's fetch data for tenant, if project has no context
  const projectTreeNodeId = project.contextWorkspace || orchestrationOsContext.navigationTree.rootId
  const {
    data: projectTree,
    isError: isProjectTreeError,
    error: projectTreeError,
  } = useFetchNavigationNodeParents({
    params: { tenantId: orchestrationOsContext.tenant.id, nodeId: projectTreeNodeId },
    staleTime: NavigationDefaults.StaleTime,
    cacheTime: NavigationDefaults.GcTime,
  })

  useEffect(() => {
    if (isProjectTreeError) {
      console.error(projectTreeError)
    }
  }, [isProjectTreeError, projectTreeError])

  const osContext = useMemo(() => {
    if (project.contextWorkspace && !projectTree) return null

    // in case of empty projectTree - return  tenant level tre
    const navigationTree = projectTree ?? orchestrationOsContext.navigationTree

    const projectHierarchyIds = resolveHierarchyPath({
      validHierarchyId: project.contextWorkspace ?? null,
      navigationTree: navigationTree,
    })

    const projectHierarchy: SelectedHierarchy = resolveSelectedHierarchy({
      activeWorkspaceId: project.contextWorkspace ?? null,
      activeHierarchyWithHiddenLevel: projectHierarchyIds,
      navigationTreeWithHiddenLevel: navigationTree,
    })

    return structuredClone({
      ...orchestrationOsContext,

      // updated context
      //by  url
      baseUrl: parentUrl,
      // by project fields values
      hierarchy: projectHierarchy,
      project: {
        ...orchestrationOsContext.project,
        processType: project.processType,
        contextHierarchy: project.contextHierarchy,
      },
      additional: {
        ...orchestrationOsContext.additional,
        projectAzId: project.azId,
      },
    }) as OsContext
  }, [
    orchestrationOsContext,
    parentUrl,
    project.azId,
    project.contextHierarchy,
    project.contextWorkspace,
    project.processType,
    projectTree,
  ])

  return {
    osApi,
    osContext,
    isError: isProjectTreeError,
  }
}
